import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { generateUrl } from "./utils/generateUrl"
import ConfigContext from "./utils/configContext"

export default class Gallery extends React.Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)
    this.state = {}
  }

  onClickHandler = (e, eventSource) => {
    e.stopPropagation()
    window._loq.push(["tag", "Download Click", true])
    window.fbq("trackCustom", "Login")
    window.gtag("event", "Download APK", {
      event_category: "Downloaded",
      event_label: eventSource,
      value: 1,
    })
  }

  componentDidMount() {
    this.slider.slickGoTo(this.props.slideToScroll)
    this.setState({
      installUrl: generateUrl(this.context.installUrl, this.context.forceUse),
    })
  }

  render() {
    let { hideGallery, imageData, bannerArray } = this.props
    const { installUrl } = this.state

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
    }
    const InstallButton = (
      <div className="column has-text-centered">
        <button
          className="button-install has-text-weight-medium"
          onClick={() => {
            hideGallery()
            this.props.fbEventAndShowModal("Gallery Button")
            this.props.togglePrompt()
          }}
        >
          {this.context.installText || "INSTALL"}
        </button>
      </div>
    )

    if (!imageData) {
      return (
        <>
          <div className="custom-modal gallery-container">
            <div
              className="gallery-close"
              onClick={hideGallery}
              onKeyDown={hideGallery}
              role="button"
              tabIndex="0"
            >
              {" "}
              <span className=" is-large">
                <strong style={{ color: "#fff" }} className="mdi ">
                  X
                </strong>
              </span>
            </div>
            <div className="gallery-content">
              <div className="gallery-slider">
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                  {bannerArray.map((item, i) => (
                    <div key={i}>
                      <img
                        src={`https://img1.getmegacdn.com/landing-page-images/${item}`}
                        alt="getmega"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              {InstallButton}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="custom-modal gallery-container">
            <div
              className="gallery-close"
              onClick={hideGallery}
              onKeyDown={hideGallery}
              role="button"
              tabIndex="0"
            >
              <span className=" is-large">
                <strong style={{ color: "#fff" }} className="mdi ">
                  X
                </strong>
              </span>
            </div>
            <div className="gallery-content">
              <div className="gallery-slider">
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                  {imageData.map(({ image }, index) => {
                    return (
                      <div key={index}>
                        <img src={image} alt="slide" />
                      </div>
                    )
                  })}
                </Slider>
              </div>
              {InstallButton}
            </div>
          </div>
        </>
      )
    }
  }
}
